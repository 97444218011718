export const findEnumViolatorAddressStatusForLanguage = (i18n, value) => {
  if (i18n.language === "en") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("foreign".startsWith((value + "").toLowerCase())) {
      return "FOREIGN";
    }
    if ("determined".startsWith((value + "").toLowerCase())) {
      return "DETERMINED";
    }
    if ("undetermined".startsWith((value + "").toLowerCase())) {
      return "UNDETERMINED";
    }
    if ("unknown".startsWith((value + "").toLowerCase())) {
      return "UNKNOWN";
    }
    if ("new".startsWith((value + "").toLowerCase())) {
      return "NEW";
    }
    if ("requested".startsWith((value + "").toLowerCase())) {
      return "REQUESTED";
    }
    return "DEFAULT";
  }
  if (i18n.language === "de") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("ausländisch".startsWith((value + "").toLowerCase())) {
      return "FOREIGN";
    }
    if ("bestimmt".startsWith((value + "").toLowerCase())) {
      return "DETERMINED";
    }
    if ("unbestimmt".startsWith((value + "").toLowerCase())) {
      return "UNDETERMINED";
    }
    if ("unbekannt".startsWith((value + "").toLowerCase())) {
      return "UNKNOWN";
    }
    if ("neu".startsWith((value + "").toLowerCase())) {
      return "NEW";
    }
    if ("angefordert".startsWith((value + "").toLowerCase())) {
      return "REQUESTED";
    }
    return "DEFAULT";
  }
  if (i18n.language === "it") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("staniero".startsWith((value + "").toLowerCase())) {
      return "FOREIGN";
    }
    if ("determinato".startsWith((value + "").toLowerCase())) {
      return "DETERMINED";
    }
    if ("indeterminato".startsWith((value + "").toLowerCase())) {
      return "UNDETERMINED";
    }
    if ("sconosciuto".startsWith((value + "").toLowerCase())) {
      return "UNKNOWN";
    }
    if ("nuovo".startsWith((value + "").toLowerCase())) {
      return "NEW";
    }
    if ("richiesto".startsWith((value + "").toLowerCase())) {
      return "REQUESTED";
    }
    return "DEFAULT";
  }
  if (i18n.language === "fr") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("étranger".startsWith((value + "").toLowerCase())) {
      return "FOREIGN";
    }
    if ("déterminé".startsWith((value + "").toLowerCase())) {
      return "DETERMINED";
    }
    if ("indéterminé".startsWith((value + "").toLowerCase())) {
      return "UNDETERMINED";
    }
    if ("inconnu".startsWith((value + "").toLowerCase())) {
      return "UNKNOWN";
    }
    if ("nouveau".startsWith((value + "").toLowerCase())) {
      return "NEW";
    }
    if ("demandé".startsWith((value + "").toLowerCase())) {
      return "REQUESTED";
    }
    return "DEFAULT";
  }
};
export const findEnumValueForLanguage = (i18n, value) => {
  if (i18n.language === "en") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("paid".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("overrdue".startsWith((value + "").toLowerCase())) {
      return "OVERDUE";
    }
    if ("canceled".startsWith((value + "").toLowerCase())) {
      return "STOPPED";
    }
    if ("due".startsWith((value + "").toLowerCase())) {
      return "ISSUED";
    }
    if ("downpaid".startsWith((value + "").toLowerCase())) {
      return "DOWNPAID";
    }
    if ("complited".startsWith((value + "").toLowerCase())) {
      return "DONE";
    }
    if ("new".startsWith((value + "").toLowerCase())) {
      return "PENDING";
    }
    return "DEFAULT";
  }
  if (i18n.language === "de") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("bezahlt".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("überfällig".startsWith((value + "").toLowerCase())) {
      return "OVERDUE";
    }
    if ("storniert".startsWith((value + "").toLowerCase())) {
      return "STOPPED";
    }
    if ("fällig".startsWith((value + "").toLowerCase())) {
      return "ISSUED";
    }
    if ("angezahlt".startsWith((value + "").toLowerCase())) {
      return "DOWNPAID";
    }
    if ("erledigt".startsWith((value + "").toLowerCase())) {
      return "DONE";
    }
    if ("neu".startsWith((value + "").toLowerCase())) {
      return "PENDING";
    }
    return "DEFAULT";
  }
  if (i18n.language === "fr") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("payée".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("überfällig".startsWith((value + "").toLowerCase())) {
      return "OVERDUE";
    }
    if ("annulée".startsWith((value + "").toLowerCase())) {
      return "STOPPED";
    }
    if ("échue".startsWith((value + "").toLowerCase())) {
      return "ISSUED";
    }
    if ("acompte".startsWith((value + "").toLowerCase())) {
      return "DOWNPAID";
    }
    if ("faite".startsWith((value + "").toLowerCase())) {
      return "DONE";
    }
    if ("nouveau".startsWith((value + "").toLowerCase())) {
      return "PENDING";
    }
    return "DEFAULT";
  }
  if (i18n.language === "it") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("pagato".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("scaduto".startsWith((value + "").toLowerCase())) {
      return "OVERDUE";
    }
    if ("annullato".startsWith((value + "").toLowerCase())) {
      return "STOPPED";
    }
    if ("dovuto".startsWith((value + "").toLowerCase())) {
      return "ISSUED";
    }
    if ("acconto pagato".startsWith((value + "").toLowerCase())) {
      return "DOWNPAID";
    }
    if ("evaso".startsWith((value + "").toLowerCase())) {
      return "DONE";
    }
    if ("nuovo".startsWith((value + "").toLowerCase())) {
      return "PENDING";
    }
    return "DEFAULT";
  }
};

export const findEnumLegalStatusValueForLanguage = (i18n, value) => {
  if (value === "-") {
    return "NONE";
  }
  if (i18n.language === "en") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("reminded".startsWith((value + "").toLowerCase())) {
      return "REMINDED";
    }
    if ("sued".startsWith((value + "").toLowerCase())) {
      return "SUED";
    }
    if ("terminated".startsWith((value + "").toLowerCase())) {
      return "TERMINATED";
    }
    if ("invoice sent".startsWith((value + "").toLowerCase())) {
      return "INVOICE_SENT";
    }

    if ("Enforced".startsWith((value + "").toLowerCase())) {
      return "OPERATION_DOC_SENT";
    }
    if ("to sue".startsWith((value + "").toLowerCase())) {
      return "TO_SUE";
    }
    if ("to enforce".startsWith((value + "").toLowerCase())) {
      return "TO_BE_ENFORCED";
    }
    if ("penalty fee demanded".startsWith((value + "").toLowerCase())) {
      return "PENALTY_FEE_DEMANDED";
    }

    return "DEFAULT";
  }

  if (i18n.language === "de") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("gemahnt".startsWith((value + "").toLowerCase())) {
      return "REMINDED";
    }
    if ("verzeigt".startsWith((value + "").toLowerCase())) {
      return "SUED";
    }
    if ("abgeschlossen".startsWith((value + "").toLowerCase())) {
      return "TERMINATED";
    }
    if ("rg versendet".startsWith((value + "").toLowerCase())) {
      return "INVOICE_SENT";
    }

    if ("betrieben".startsWith((value + "").toLowerCase())) {
      return "OPERATION_DOC_SENT";
    }
    if ("zu verzeigen".startsWith((value + "").toLowerCase())) {
      return "TO_SUE";
    }
    if ("zu betreiben".startsWith((value + "").toLowerCase())) {
      return "TO_BE_ENFORCED";
    }
    if ("strafgebühr eingefordert".startsWith((value + "").toLowerCase())) {
      return "PENALTY_FEE_DEMANDED";
    }

    return "DEFAULT";
  }
  if (i18n.language === "fr") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("rappelée".startsWith((value + "").toLowerCase())) {
      return "REMINDED";
    }
    if ("dénoncée".startsWith((value + "").toLowerCase())) {
      return "SUED";
    }
    if ("fermé".startsWith((value + "").toLowerCase())) {
      return "TERMINATED";
    }
    if ("facture envoyée".startsWith((value + "").toLowerCase())) {
      return "INVOICE_SENT";
    }

    if ("poursuite en cours".startsWith((value + "").toLowerCase())) {
      return "OPERATION_DOC_SENT";
    }
    if ("à dénoncer".startsWith((value + "").toLowerCase())) {
      return "TO_SUE";
    }
    if ("introduire poursuite".startsWith((value + "").toLowerCase())) {
      return "TO_BE_ENFORCED";
    }
    if ("pénalité demandée".startsWith((value + "").toLowerCase())) {
      return "PENALTY_FEE_DEMANDED";
    }

    return "DEFAULT";
  }
};

export const findUserStatusEnumValueForLanguage = (i18n, value) => {
  if (i18n.language === "en") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("paid".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("open".startsWith((value + "").toLowerCase())) {
      return "OPEN";
    }
    if ("cancelled".startsWith((value + "").toLowerCase())) {
      return "CANCELLED";
    }
    if ("closed".startsWith((value + "").toLowerCase())) {
      return "CLOSED";
    }

    return "DEFAULT";
  }
  if (i18n.language === "de") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("bezahlt".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("offen".startsWith((value + "").toLowerCase())) {
      return "OPEN";
    }
    if ("storniert".startsWith((value + "").toLowerCase())) {
      return "CANCELLED";
    }
    if ("geschlossen".startsWith((value + "").toLowerCase())) {
      return "CLOSED";
    }

    return "DEFAULT";
  }
  if (i18n.language === "fr") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("paye".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("ouvert".startsWith((value + "").toLowerCase())) {
      return "OPEN";
    }
    if ("annule".startsWith((value + "").toLowerCase())) {
      return "CANCELLED";
    }
    if ("ferme".startsWith((value + "").toLowerCase())) {
      return "CLOSED";
    }

    return "DEFAULT";
  }
  if (i18n.language === "it") {
    if (value === "") {
      return "DEFAULT";
    }
    if ("pagato".startsWith((value + "").toLowerCase())) {
      return "PAID";
    }
    if ("aperto".startsWith((value + "").toLowerCase())) {
      return "OPEN";
    }
    if ("annullato".startsWith((value + "").toLowerCase())) {
      return "CANCELLED";
    }
    if ("chiuso".startsWith((value + "").toLowerCase())) {
      return "CLOSED";
    }

    return "DEFAULT";
  }
};

export const mapVehicleTypeToEnglish = (i18n, value) => {
  const lowerValue = value.toLowerCase();

  if (i18n.language === "de") {
    if (lowerValue.startsWith("mot")) {
      return "Motorcycle";
    } else if (lowerValue.startsWith("aut")) {
      return "Car";
    } else if (lowerValue.startsWith("unb")) {
      return "";
    } else if (lowerValue.startsWith("and")) {
      return "Other";
    }
  } else if (i18n.language === "en") {
    if (lowerValue.startsWith("mot")) {
      return "Motorcycle";
    } else if (lowerValue.startsWith("car")) {
      return "Car";
    } else if (lowerValue.startsWith("unk")) {
      return "";
    } else if (lowerValue.startsWith("oth")) {
      return "Other";
    }
  } else if (i18n.language === "fr") {
    if (lowerValue.startsWith("mot")) {
      return "Motorcycle";
    } else if (lowerValue.startsWith("voi")) {
      return "Car";
    } else if (lowerValue.startsWith("inc")) {
      return "";
    } else if (lowerValue.startsWith("aut")) {
      return "Other";
    }
  } else if (i18n.language === "it") {
    if (lowerValue.startsWith("mot")) {
      return "Motorcycle";
    } else if (lowerValue.startsWith("aut")) {
      return "Car";
    } else if (lowerValue.startsWith("sco")) {
      return "";
    } else if (lowerValue.startsWith("alt")) {
      return "Other";
    }
  }

  return "Other";
};

export const mapCountryCode = (value) => {
  const validCountryCodes = [
    "CH",
    "D",
    "F",
    "I",
    "A",
    "FL",
    "other",
  ];
  const upperValue = value.toUpperCase();

  if (upperValue === "O") {
    return "other";
  }
  if (upperValue === "" || !validCountryCodes.includes(upperValue)) {
    return "";
  }
  return upperValue;
};

export const isUnknown = (input) => {
  const lowerValue = input.toLowerCase();
  const unknownTranslations = [
    "unknown",
    "unbekannt",
    "sconosciuto",
    "inconnu",
  ];
  return unknownTranslations.some((unknown) => unknown.startsWith(lowerValue));
};
