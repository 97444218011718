import de from "date-fns/locale/de";
import {useEffect, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {getBeppolicemanGasStationZones, getBeppolicemanParkingZones, getTicketForEdit,} from "../../actions/violations";
import Button from "../../common/button/button";
import EntityDetail from "../../common/entityDetail/entityDetail";
import SelectWithLabel from "../../common/input/selectWithLabel";
import LabelWithHR from "../../common/labels/labelWithHR";
import {newModalStyle} from "../../reusable/styles";
import Style from "./modalEditTicket.module.css";

registerLocale("de", de);

const ModalEditTicket = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, control, register, watch } = useForm();
  const { ownersParkingZonesForEditingTicket } = useSelector(
    (state) => state.violations
  );
  const { ticketForEdit } = useSelector((state) => state.violations);

  const { ownersGasStationZonesForEditingTicket } = useSelector(
    (state) => state.violations
  );

  const [plateNumber, setPlateNumber] = useState(null);
  const [paymentPeriod, setPaymentPeriod] = useState(0);
  const [reminderPaymentPeriod, setReminderPaymentPeriod] = useState(0);
  const [defaultParkingZone, setDefaultParkingZone] = useState(null);
  const [defaultGasStationZone, setDefaultGasStationZone] = useState(null);
  const parkingZoneWatch = watch("parkingZone", null);
  const gasStationZoneWatch = watch("gasStationZone", null);
  const [date, setDate] = useState(new Date(props.ticket?.date));

  const [pickerHeight, setPickerHeight] = useState(50);

  useEffect(() => {
    dispatch(getTicketForEdit(props.ticket?.number));
    if (props.ticket?.parkingZone?.parkingZoneNumber) {
      dispatch(
        getBeppolicemanParkingZones(props.ticket?.reporter?.email.toString())
      );
    }
    if (props.ticket?.gasStationZone?.number) {
      dispatch(
        getBeppolicemanGasStationZones(props.ticket?.reporter?.email.toString())
      );
    }
  }, [props.ticket?.reporter?.email, dispatch]);

  useEffect(() => {
    setPlateNumber(ticketForEdit?.licensePlate);
    setPaymentPeriod(ticketForEdit?.paymentPeriod);
    setReminderPaymentPeriod(ticketForEdit?.reminderPaymentPeriod);
    setDefaultParkingZone(ticketForEdit?.parkingZone?.parkingZoneNumber);
    setDefaultGasStationZone(ticketForEdit?.gasStationZone?.number);
  }, [ticketForEdit]);

  const changeData = () => {
    const id = ticketForEdit?.id;
    const pz =
      parkingZoneWatch !== null ? parseInt(parkingZoneWatch) : null;
    const gs =
      gasStationZoneWatch !== null
        ? parseInt(gasStationZoneWatch)
        : null;

    const data = {
      id,
      plateNumber,
      date,
      parkingZoneNumber: pz,
      gasStationZoneNumber: gs,
      paymentPeriod,
      reminderPaymentPeriod,
    };

    props.editTicket(data);
  };

  const { t } = useTranslation();

  const pzZone = ownersParkingZonesForEditingTicket?.find(
    (pz) => pz.parkingZoneNumber === parseInt(defaultParkingZone)
  );

  const gsZone = ownersGasStationZonesForEditingTicket?.find(
    (gs) => gs.gasStationZoneNumber === defaultGasStationZone
  );

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <form onSubmit={handleSubmit(changeData)}>
        <div className="modal__title">{t("Violations.editTicket")}</div>

        <div>
          <LabelWithHR label={t("ModalEditTicket.plateNumber")} />
          <Controller
            name="plateNumber"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                name="plateNumber"
                onChange={(e) => {
                  setPlateNumber(e.target.value);
                }}
                defaultValue={plateNumber}
              />
            )}
          />
        </div>
        {defaultGasStationZone && (
          <>
            <SelectWithLabel
              name="gasStationZone"
              label={t("ModalEditTicket.GasStation")}
              style={{ marginBottom: "0px" }}
              whiteBackground
              registerName="gasStationZone"
              register={register}
              onChange={(e) => setDefaultGasStationZone(e.target.value)}
              optionValue={ticketForEdit?.gasStationZone.number}
              defaultOption={
                ticketForEdit?.gasStationZone?.number +
                "-" +
                ticketForEdit?.gasStationZone?.name
              }
              options={ownersGasStationZonesForEditingTicket?.map(
                (gs, index) => {
                  if (
                    gs.gasStationZoneNumber ===
                    ticketForEdit?.gasStationZone?.number
                  ) {
                    return;
                  }
                  return (
                    <option key={index + 1} value={gs.gasStationZoneNumber}>
                      {gs.gasStationZoneNumber + " - " + gs.gasStationZoneName}
                    </option>
                  );
                }
              )}
            />
            {ticketForEdit?.gasStationZone?.number !==
              defaultGasStationZone && (
              <div
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  marginTop: "5px",
                  borderRadius: "5px",
                }}
              >
                <EntityDetail
                  label={t("GasStations.GS name")}
                  value={gsZone?.gasStationZoneName}
                />
                <EntityDetail
                  label={t("GasStations.GS number")}
                  value={gsZone?.gasStationZoneNumber}
                />
                <EntityDetail
                  label={t("GasStations.GS address")}
                  value={gsZone?.address}
                />
                <EntityDetail
                  label={t("GasStations.additionalFee")}
                  value={gsZone?.additionalFee}
                />
                <EntityDetail
                  label={t("GasStations.paymentPeriod")}
                  value={gsZone?.paymentPeriod}
                />

                <EntityDetail
                  label={t("GasStations.reminderPaymentPeriod")}
                  value={gsZone?.reminderPaymentPeriod}
                />
              </div>
            )}
          </>
        )}
        {defaultParkingZone && (
          <>
            <SelectWithLabel
              name="parkingZone"
              label={t("ModalEditTicket.ParkingZone")}
              style={{ marginBottom: "0px" }}
              whiteBackground
              registerName="parkingZone"
              register={register}
              onChange={(e) => setDefaultParkingZone(e.target.value)}
              optionValue={ticketForEdit?.parkingZone.parkingZoneNumber}
              defaultOption={
                ticketForEdit?.parkingZone?.parkingZoneNumber +
                "-" +
                ticketForEdit?.parkingZone?.parkingZoneName
              }
              options={ownersParkingZonesForEditingTicket?.map((pz, index) => {
                if (
                  pz.parkingZoneNumber ===
                  ticketForEdit?.parkingZone?.parkingZoneNumber
                ) {
                  return;
                }
                return (
                  <option key={index + 1} value={pz.parkingZoneNumber}>
                    {pz.parkingZoneNumber + " - " + pz.parkingZoneName}
                  </option>
                );
              })}
            />
            {ticketForEdit?.parkingZone?.parkingZoneNumber !=
              defaultParkingZone && (
              <div
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  marginTop: "5px",
                  borderRadius: "5px",
                }}
              >
                <EntityDetail
                  label={t("ParkingZones.PZ name")}
                  value={pzZone?.parkingZoneName}
                />
                <EntityDetail
                  label={t("ParkingZones.PZ number")}
                  value={pzZone?.parkingZoneNumber}
                />
                <EntityDetail
                  label={t("ParkingDetails.Parking zone address")}
                  value={pzZone?.address}
                />
                <EntityDetail
                  label={t("ParkingZones.price")}
                  value={pzZone?.price}
                />
                <EntityDetail
                  label={t("ParkingZones.paymentPeriod")}
                  value={pzZone?.paymentPeriod}
                />

                <EntityDetail
                  label={t("ParkingZones.reminderPaymentPeriod")}
                  value={pzZone?.reminderPaymentPeriod}
                />
              </div>
            )}
          </>
        )}

        <div>
          <LabelWithHR label={t("ModalEditTicket.date")} />
          <Controller
            name="additionalCost"
            control={control}
            render={() => (
              <DatePicker
                selected={date}
                className={Style.textStyleInputEditModal}
                showTimeSelect
                required
                locale="de"
                dateFormat="Pp"
                onChange={(date) => setDate(date)}
                onCalendarOpen={() => setPickerHeight(300)}
                onCalendarClose={() => setPickerHeight(50)}
                timeIntervals={1}
              />
            )}
          />
        </div>

        <div>
          <LabelWithHR label={t("ModalEditTicket.paymentPeriod")} />
          <Controller
            name="paymentPeriod"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                name="paymentPeriod"
                onChange={(e) => {
                  setPaymentPeriod(e.target.value);
                }}
                defaultValue={ticketForEdit?.paymentPeriod}
              />
            )}
          />
        </div>

        {ticketForEdit?.reminderDeadline && (
          <div>
            <LabelWithHR label={t("ModalEditTicket.reminderPaymentPeriod")} />
            <Controller
              name="reminderPaymentPeriod"
              control={control}
              render={() => (
                <input
                  className={Style.textStyleInputEditModal}
                  name="reminderPaymentPeriod"
                  onChange={(e) => {
                    setReminderPaymentPeriod(e.target.value);
                  }}
                  defaultValue={ticketForEdit?.reminderPaymentPeriod}
                />
              )}
            />
          </div>
        )}

        <div className={Style.buttonContainer}>
          <Button
            style={{
              width: "25%",
              marginTop: pickerHeight,
            }}
            buttonStyle={{
              width: "100%",
            }}
            text={t("ModalEditTicket.save")}
            disabled={paymentPeriod < 0 || reminderPaymentPeriod < 0}
          />
        </div>
      </form>
    </Modal>
  );
};
export default ModalEditTicket;
